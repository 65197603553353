import clsx from "clsx";
import { useScroll } from "../helpers/Hooks";

export default function Calendar(props) {
    const { className } = props;
    const headerRef = useScroll();
    const tableRef = useScroll()

    return (
        <div className={clsx("calendar", className)}>
            <header className="calendar__header fadeInUp" {...headerRef}>
                <div className="calendar__month">
                    <h2 className="calendar__month-kor">4월</h2>
                    <p className="calendar__month-eng">April</p>
                </div>
            </header>
            <table className="calendar-table calendar__table fadeInUp" {...tableRef}>
                <thead>
                <tr>
                    <td>일</td>
                    <td>월</td>
                    <td>화</td>
                    <td>수</td>
                    <td>목</td>
                    <td>금</td>
                    <td>토</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>10</td>
                    <td>11</td>
                    <td>12</td>
                    <td>13</td>
                    <td>14</td>
                    <td>15</td>
                </tr>
                <tr>
                    <td>16</td>
                    <td>17</td>
                    <td>18</td>
                    <td>19</td>
                    <td>20</td>
                    <td>21</td>
                    <td>22</td>
                </tr>
                <tr>
                    <td>23</td>
                    <td>24</td>
                    <td>25</td>
                    <td>26</td>
                    <td>27</td>
                    <td>28</td>
                    <td>29</td>
                </tr>
                <tr>
                    <td className="calendar-table__d-day">30</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}