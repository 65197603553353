import StarContact from "./StarContact";
import HostContact from "./HostContact";
import BankAccount from "./BankAccount";
import HostAccount from "./HostAccount";
import clsx from "clsx";

export default function ContactPopup(props) {
    const { className, isOpen, onClose } = props;
    return (
        <div className={clsx("popup", isOpen && ["popup--is-open", "animated"])}>
            <div className="popup__container fadeInUp">
                <section className="account popup__account">
                    <div className="account__container">
                        <h2 className="popup__title">마음을 전합니다</h2>
                        <main className="popup__content">
                            <div className="account-of-stars">
                                <BankAccount
                                    color="blue"
                                    role="신랑"
                                    name="김창환"
                                    account={
                                        {
                                            bank: "우리은행",
                                            number: "1002-240-307088",
                                            name: "김창환"
                                        }
                                    }
                                />
                                <BankAccount
                                    color="red"
                                    role="신부"
                                    name="오가영"
                                    account={
                                        {
                                            bank: "카카오뱅크",
                                            number: "3333-07-5192108",
                                            name: "오가영"
                                        }
                                    }
                                />
                            </div>
                            <div className="account-of-hosts account-of-hosts--side-broom">
                                <p className="account-of-hosts__title">신랑측 혼주</p>
                                <div className="account-of-hosts__content">
                                    <BankAccount
                                        color="blue"
                                        role="아버지"
                                        name="김재홍"
                                        account={
                                            {
                                                bank: "IBK 기업은행",
                                                number: "010-3772-7256",
                                                name: "김재홍"
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className="account-of-hosts account-of-hosts--side-bride">
                                <p className="account-of-hosts__title">신부측 혼주</p>
                                <div className="account-of-hosts__content">
                                    <BankAccount
                                        color="red"
                                        role="아버지"
                                        name="오연식"
                                        bank="IBK 기업은행"
                                        accountNumber="010-4559-0691"
                                        account={
                                            {
                                                bank: "IBK 기업은행",
                                                number: "010-4559-0691",
                                                name: "오연식"
                                            }
                                        }
                                    />
                                    <BankAccount
                                        color="red"
                                        role="어머니"
                                        name="전애희"
                                        bank="대구은행"
                                        account={
                                            {
                                                bank: "대구은행",
                                                number: "093-08-260754",
                                                name: "전애희"
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </main>
                    </div>
                </section>
                <a className="popup__close-button" href="#" onClick={onClose}></a>
            </div>
        </div>
    )
}