import phoneIcon from "img/icon_phone.png";
import clsx from "clsx";
import weddingGif from "img/gif_wedding.gif";
import coupleIcon from "img/icon_couple.png";
import {useScroll} from "../helpers/Hooks";

export default function BusPage(props) {
    const { className, animated } = props;
    const titleRef = useScroll();
    const subtitleRef = useScroll();
    const busPointsRef = useScroll();
    const busInfoRef = useScroll();

    return (
        <div className={clsx("page", "bus-page", className)}>
            <h1 className="page__title fadeInUp" { ...titleRef }>신부측 버스 대절</h1>
            <p className="page__subtitle fadeInUp" { ...subtitleRef }>
                소중한 시간 내어
                <br/>
                먼 걸음 해주셔서 감사합니다
            </p>
            <div className="bus-points fadeInUp" { ...busPointsRef }>
                <p className="bus-points__title">
                    2023년 4월 30일(일)
                    <br/>
                    대구 출발 → 구미 → 서울
                </p>
                <div className="bus-points__content">
                    <section className="bus-point">
                        <h2 className="bus-point__title"><span>대구 출발</span></h2>
                        <dl className="bus-point__desc">
                            <dt className="bus-point__name">시간</dt>
                            <dd className="bus-point__value">오전 8시</dd>
                            <dt className="bus-point__name">장소</dt>
                            <dd className="bus-point__value">시민운동장(축구장 쪽) 시계탑 앞 </dd>
                        </dl>
                    </section>
                    <section className="bus-point">
                        <h2 className="bus-point__title"><span>구미</span></h2>
                        <dl className="bus-point__desc">
                            <dt className="bus-point__name">시간</dt>
                            <dd className="bus-point__value">오전 8시 30분</dd>
                            <dt className="bus-point__name">장소</dt>
                            <dd className="bus-point__value">구미고속도로 입구 주차장 출발</dd>
                        </dl>
                    </section>
                    <section className="bus-point bus-point--wedding">
                        <h2 className="bus-point__title">
                            <img src={coupleIcon}/>
                        </h2>
                        <div className="bus-point__desc">
                            <p className="bus-point__name">예식</p>
                            <p className="bus-point__value">오후 1시 50분</p>
                        </div>
                    </section>
                    <section className="bus-point">
                        <h2 className="bus-point__title"><span>서울 출발</span></h2>
                        <dl className="bus-point__desc">
                            <dt className="bus-point__name">장소</dt>
                            <dd className="bus-point__value">더베뉴지서울 정문</dd>
                        </dl>
                    </section>
                </div>
            </div>
            <section className="bus-info fadeInUp" { ...busInfoRef }>
                <h2 className="bus-info__title"><span>버스 정보</span></h2>
                <div className="bus-info__content">
                    <dl className="bus-info__desc">
                        <dt className="bus-info__name">차량</dt>
                        <dd className="bus-info__value">예스 관광</dd>
                        <dt className="bus-info__name">인솔자</dt>
                        <dd className="bus-info__value">
                            오범식 (대구) 010-3259-2005
                            <a className="bus-info__button" href="tel:010-3259-2005">
                                <img src={phoneIcon}/>
                            </a>
                        </dd>
                        <dd className="bus-info__value">
                            오준식 (구미) 010-9019-0949
                            <a className="bus-info__button" href="tel:010-9019-0949">
                                <img src={phoneIcon}/>
                            </a>
                        </dd>
                    </dl>
                </div>
            </section>
        </div>
    );
}