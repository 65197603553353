import clsx from "clsx";
import { useEffect, useRef } from "react";
import video1 from "intro.mp4";
import poster from "img/poster_video.png";

export default function FrontPage(props) {
    const { className, play } = props;
    const video = useRef();
    useEffect(() => {
        if(video && play) {
            video.current.muted = true;
            video.current.play();
        }
    }, [video, play])

    return (
        <div className={clsx( "page", "front-page", className, play && "animated")}>
            <div className="front-page__frame">
                <div className="frame">
                    <p className="frame__couple">김창환 & 오가영</p>
                    <p className="frame__datetime">2023년 4월 30일 오후 1시 50분</p>
                    <p className="frame__place">더베뉴지서울 1층 네이처홀</p>
                    <div className="frame__video">
                        <video poster={poster} src={video1} ref={video} playsInline loop/>
                    </div>
                    <p className="frame__guide">
                        <i className="frame__arrow"/>
                        <br/>
                        위로
                        <br/>
                        스크롤해 주세요
                    </p>
                </div>
            </div>
        </div>
    )
}