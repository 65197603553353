import clsx from "clsx";
import phoneIcon from "img/icon_phone.png";
import { useScroll } from "../helpers/Hooks";

export default function IntroPage(props) {
    const { className, onContactClick, onAccountClick } = props;
    const titleRef = useScroll();
    const messageRef = useScroll();
    const introFromRef = useScroll();

    return (
        <div id="intro-page" className={clsx("page", "intro-page", className)}>
            <h1 className="page__title intro-page__title fadeInUp" {...titleRef}>
                봄을 담아
                <br/>
                당신을 초대합니다
            </h1>
            <p className="intro-page__message fadeInUp" {...messageRef}>
                4월의 마지막 날
                <br/>
                저희 두 사람
                <br/>
                생의 모든 계절을 함께하고자 합니다.
                <br/>
                <br/>
                눈부신 봄날
                <br/>
                시작의 순간에 귀한 걸음하셔서
                <br/>
                자리를 빛내주세요.
            </p>
            <div className="intro-page__from" {...introFromRef}>
                <div className="intro-from intro-from--broom fadeInUp animated--order-1">
                    <p className="intro-from__text">
                        <span className="intro-from__father">김재홍</span>
                        <span className="intro-from__divider"> · </span>
                        <span className="intro-from__mother">
                    <span className="intro-from__pass-away">(故)</span>
                        윤혜옥
                    </span>
                        <span className="intro-from__of">의 장남</span>
                        <span className="intro-from__star">창환</span>
                    </p>
                    <a className="intro-from__call" href="tel:010-6628-7256">
                        <img src={phoneIcon}/>
                    </a>
                </div>
                <p className="intro-from intro-from--bride fadeInUp animated--order-2">
                    <p className="intro-from__text">
                        <span className="intro-from__father">오연식</span>
                        <span className="intro-from__divider"> · </span>
                        <span className="intro-from__mother">전애희</span>
                        <span className="intro-from__of">의 장녀</span>
                        <span className="intro-from__star">가영</span>
                    </p>
                    <a className="intro-from__call" href="tel:010-9319-0425">
                        <img src={phoneIcon}/>
                    </a>
                </p>
            </div>
            <div className="intro-page__action">
                <a className="button button--size-large button--color-navy" onClick={onContactClick}>
                    혼주에게 연락하기
                </a>
                <a className="button button--size-large button--outline button--color-navy" onClick={onAccountClick}>
                    마음을 전합니다 (계좌번호)
                </a>
            </div>
        </div>
    );
}
