import clsx from "clsx";
import phoneIcon from "img/icon_phone.png";
import smsIcon from "img/icon_sms.png";

export default function HostContact(props) {
    const { className, role, name, color, phoneNumber } = props;

    return (
        <div className={clsx("host-contact", className, color && `host-contact${color}`)}>
            <p className="host-contact__title">
                <span className="host-contact__role">{role}</span>
                <span className="host-contact__name">{name}</span>
            </p>
            <nav className="host-contact__content">
                <a className="host-contact__button host-contact__button--by-phone" href={`tel:${phoneNumber}`}>
                    <img src={phoneIcon}/>
                </a>
                <a className="host-contact__button host-contact__button--by-sms" href={`sms:${phoneNumber}`}>
                    <img src={smsIcon}/>
                </a>
            </nav>
        </div>
    )
}