import React, { useRef, useState } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper";
import { useScroll } from "../helpers/Hooks";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export default function GalleryPage(props) {
    const { className, pictures, animated } = props;
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const titleRef = useScroll();
    const slidersRef = useScroll();

    const slideElements = pictures.map((picture, index) => {
        return (
            <SwiperSlide key={index}>
                <img src={picture} />
            </SwiperSlide>
        );
    })

    return (
        <div className={clsx( "page", "gallery-page", className)}>
            <h1 className="page__title gallery-page__title fadeInUp" { ...titleRef }>
                우리의 순간들
            </h1>
            <div className="gallery-page__sliders fadeInUp" { ...slidersRef }>
                <Swiper
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                    }}
                    loop
                    centeredSlides
                    allowTouchMove={false}
                    spaceBetween={10}
                    navigation={true}
                    modules={[FreeMode, Navigation, Thumbs, Pagination]}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    pagination={{ type: "fraction" }}
                    className="gallery-slider animated--order-1"
                >
                    {slideElements}
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={4}
                    slidesPerView={4.5}
                    modules={[Thumbs]}
                    className="gallery-thumbs-slider animated--order-2"
                >
                    {slideElements}
                </Swiper>
            </div>
        </div>
    );
}
