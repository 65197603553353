import clsx from "clsx";
import { useScroll } from "../helpers/Hooks";

export default function ThankyouPage(props) {
    const { className, animated } = props;
    const titleRef = useScroll();
    const subtitleRef = useScroll();
    const thanksToRef = useScroll();
    const youtubeRef = useScroll();
    const lastRef = useScroll();

    return (
        <div className={clsx("page", "thankyou-page", className)}>
            <h1 className="page__title thankyou-page__title fadeInUp" { ...titleRef }>저희 두 사람의 출발을<br/>축복해주세요.</h1>
            <p className="page__subtitle thankyou-page__subtitle fadeInUp" { ...subtitleRef }>
                고마운 이들
            </p>
            <p className="thanks-to fadeInUp" { ...thanksToRef }>
               셀프웨딩 촬영 : 공주현
                <br/>
                <br/>
                신부가 쓰고 신랑이 만들다.
                <br/>
                (생각보다 시간이 많이 걸렸습니다...)
            </p>
            <iframe className="spongebob fadeInUp" { ...youtubeRef } width="280" height="157" src="https://www.youtube.com/embed/iiv503NT-Fs"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
            <p className="thanks-to thanks-to--last fadeInUp" { ...lastRef }>
                감사합니다 ❤
            </p>
        </div>
    );
}
