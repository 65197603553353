import {useEffect, useState} from "react";
import clsx from "clsx";
import FrontPage from "pages/FrontPage";
import IntroPage from "pages/IntroPage";
import CalendarPage from "pages/CalendarPage";
import StarPage from "pages/StarPage";
import GalleryPage from "pages/GalleryPage";
import LocationPage from "pages/LocationPage";
import BusPage from "pages/BusPage";
import FrontCover from "./components/FrontCover";
import ContactPopup from "./components/ContactPopup";
import AccountPopup from "./components/AccountPopup";
import './App.css';
import ContactPage from "./pages/ContactPage";
import ThankyouPage from "./pages/ThankyouPage";

const importAll = (r) => {
    let images = [];
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

    r.keys().filter(item => item.indexOf('./') !== 0).sort(collator.compare).map(item => {
        images.push(r(item));
    });
    return images;
}

const brideProfileImages = importAll(require.context('./img/bride', false, /\.jpg$/));
const broomProfileImages = importAll(require.context('./img/broom', false, /\.jpg$/));
const galleryImages = importAll(require.context('./img/gallery', false, /\.jpg$/));

function App() {
    const [isCoverRemoved, setIsCoverRemoved] = useState(false);
    const removeCover = () => {
        setIsCoverRemoved(true);
    }

    const [isOpenContactPopup, setIsOpenContactPopup] = useState(false);
    const openContactPopup = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setIsOpenContactPopup(true)
    }
    const closeContactPopup = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        setIsOpenContactPopup(false)
    }

    const [isOpenAccountPopup, setIsOpenAccountPopup] = useState(false);
    const openAccountPopup = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setIsOpenAccountPopup(true)
    }
    const closeAccountPopup = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        setIsOpenAccountPopup(false);
    }

    useEffect(() => {
        if(isCoverRemoved) {
            setTimeout(() => {
                document.body.style.overflow = "auto";
            }, 500);
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [isCoverRemoved]);

    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        setScreenSize();
        window.addEventListener('resize', setScreenSize);
    }, [])

    return (
        <div className="App">
            <FrontCover coverRemoved={isCoverRemoved} onRemoveCover={removeCover}/>
            <div className="app-container">
                <FrontPage play={isCoverRemoved}/>
                <IntroPage onContactClick={openContactPopup} onAccountClick={openAccountPopup}/>
                <CalendarPage hideTitle={false}/>
                <StarPage
                    className="star-page--bride"
                    key={"bride"}
                    position="신부"
                    means={["매일매일 새로운 그녀"]}
                    pictures={brideProfileImages}
                    name="오가영"
                    birthday="1990.04.25"
                    job="영상 콘텐츠 작가"
                    ability="본 청첩장을 직접 기획"
                />
                <StarPage
                    className="star-page--broom"
                    key={"broom"}
                    position="신랑"
                    means={["눈부신 햇살을 닮은 그"]}
                    pictures={broomProfileImages}
                    name="김창환"
                    birthday="1987.10.21"
                    job="개발자"
                    ability="본 청첩장을 직접 개발"
                />
                <GalleryPage
                    pictures={galleryImages}
                />
                <LocationPage/>
                <BusPage/>
                <ThankyouPage/>
            </div>
            <ContactPopup isOpen={isOpenContactPopup} onClose={closeContactPopup}/>
            <AccountPopup isOpen={isOpenAccountPopup} onClose={closeAccountPopup}/>
        </div>
    );
}

export default App;
