import Calendar from "../components/Calendar";
import clsx from "clsx";

export default function CalendarPage(props) {
    const { className, hideTitle, animated } = props;

    return (
        <div className={clsx( "page", "calendar-page", className, hideTitle && "page--hide-title", animated && "animated")}>
            <Calendar className="calendar-page__calendar"/>
        </div>
    )
}