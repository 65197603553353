import clsx from "clsx";
import tmapIcon from "../img/icon_tmap.png";
import kakaoNaviIcon from "../img/icon_kakao-navi.png";
import naverMapIcon from "../img/icon_naver-map.png";
import kakaoMapIcon from "../img/icon_kakao-map.png";
import phoneIcon from "../img/icon_phone.png";
import { useEffect } from "react";
import { useScroll } from "../helpers/Hooks";

const Lat = 37.56018455390987;
const Lng = 126.8393063469143;
const initMap = (kakao, container) => {
    const options = { //지도를 생성할 때 필요한 기본 옵션
        center: new kakao.maps.LatLng(Lat, Lng), //지도의 중심좌표.
        level: 3, //지도의 레벨(확대, 축소 정도),
        draggable: false,
        scrollwheel: false
    };

    const map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

    const markerPosition  = new window.kakao.maps.LatLng(Lat, Lng);
    const marker = new window.kakao.maps.Marker({
        position: markerPosition
    })

    marker.setMap(map);
}

export default function LocationPage(props) {
    const { className, animated } = props;
    const titleRef = useScroll();
    const mapRef = useScroll();
    const linksRef = useScroll();
    const howToComeRef = useScroll();

    useEffect(()=> {
        const container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
        initMap(window.kakao, container);
    }, [])

    return (
        <div className={clsx("page", "location-page", className, animated && "animated")}>
            <h1 className="page__title location-page__title fadeInUp" { ...titleRef }>오시는 길</h1>
            <div className="location-map location-page__map" { ...mapRef }>
                <div id="map" className="location-map__map fadeInUp animated--order-1">
                </div>
                <div className="location-map__content fadeInUp animated--order-2">
                    <div className="location-map__text">
                        <p className="location-map__name">
                            더베뉴지서울
                        </p>
                        <address className="location-map__address">
                            서울시 강서구 강서로 388
                        </address>
                    </div>
                    <a className="location-map__tel">
                        <img src={phoneIcon}/>
                    </a>
                </div>
            </div>
            <nav className="provider-links fadeInUp" { ...linksRef }>
                <ul className="provider-links__list">
                    <li className="provider-links__item">
                        <a
                           className="provider-link provider-link--to-tmap"
                           href="https://surl.tmap.co.kr/6ca91ebd"
                        >
                            <img src={tmapIcon}/>
                            <span>티맵</span>
                        </a>
                    </li>
                    <li className="provider-links__item">
                        <a className="provider-link provider-link--to-naver-map">
                            <img src={naverMapIcon}/>
                            <span>네이버지도</span>
                        </a>
                    </li>
                    <li className="provider-links__item">
                        <a className="provider-link provider-link--to-kakao-map">
                            <img src={kakaoMapIcon}/>
                            <span>카카오맵</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <dl className="how-to-come fadeInUp" { ...howToComeRef }>
                <dt className="how-to-come__title">지하철 안내</dt>
                <dd className="how-to-come__content">
                    5호선 발산역 3번 출구(도보 1분 이내)
                    <br/>
                    9호선 양천향교역 6번 출구(도보 10분)
                </dd>
                <dt className="how-to-come__title">버스 안내</dt>
                <dd className="how-to-come__content how-to-come__content--by-bus">
                    <span className="how-to-come__by how-to-come__by--green">지선버스</span> : 6630, 6632, 6642, 6645, 6648, 6657, 6712
                    <br/>
                    <span className="how-to-come__by how-to-come__by--blue">간선버스</span> : 601, 605, 652, 654, 661
                    <br/>
                    <span className="how-to-come__by how-to-come__by--yellow-green">일반버스</span> : 60, 60-3, 88, 1002
                </dd>
                <dt className="how-to-come__title">주차 안내</dt>
                <dd className="how-to-come__content">
                    웨딩홀 내 주차장 이용(무료 2시간)
                </dd>
            </dl>
        </div>
    )
}