import { useRef, useCallback, useEffect } from "react";

export const useScroll = () => {
    const dom = useRef();

    const handleScroll = useCallback(([entry]) => {
        const { current } = dom;

        if(entry.isIntersecting) {
            current.classList.remove("animated--before");
            current.classList.add("animated");
        }
    }, []);

    useEffect(() => {
        let observer;
        const { current } = dom;

        if (current) {
            observer = new IntersectionObserver(handleScroll, { threshold: 0.5 });
            observer.observe(current);

            return () => observer && observer.disconnect();
        };
    }, [handleScroll]);

    return {
        ref: dom,
    };
}
