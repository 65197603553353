import clsx from "clsx";
import copyIcon from "img/icon_copy.png";

export default function BankAccount(props) {
    const {
        className,
        color,
        role,
        name,
        account,
    } = props;
    const copyBankInfo = (e) => {
        e.preventDefault();
        window.navigator.clipboard.writeText(e.currentTarget.dataset.info);
        setTimeout(() => alert("복사 완료되었습니다."), 300 );
    }

    return (
        <div className={clsx("bank-account", className, color && `bank-account--${color}`)}>
            <p className="bank-account__to">
                <span className="bank-account__role">{role}</span>
                <span className="bank-account__name">{name}</span>
            </p>
            <div className="bank-account__account">
                <p className="bank-account__account-info">
                    <span className="bank-account__account-bank">{account.bank}</span>
                    <br/>
                    <span className="bank-account__account-number">{account.number}</span>
                    <br/>
                    <span className="bank-account__account-name">예금주: {account.name}</span>
                </p>
                <a className="bank-account__copy-button" href="#" data-info={`${account.bank} ${account.number}`} onClick={copyBankInfo}>
                    계좌 복사
                </a>
            </div>
        </div>
    )
}