import StarContact from "./StarContact";
import HostContact from "./HostContact";
import BankAccount from "./BankAccount";
import HostAccount from "./HostAccount";
import clsx from "clsx";

export default function ContactPopup(props) {
    const { className, isOpen, onClose } = props;
    return (
        <div className={clsx("popup", isOpen && ["popup--is-open", "animated"])}>
            <div className="popup__container fadeInUp">
                <section className="contact popup__contact">
                    <h2 className="popup__title">연락하기</h2>
                    <main className="popup__content">
                        <div className="contact-to-hosts contact-to-hosts--side-broom contact__to-hosts">
                            <p className="contact-to-hosts__title">신랑측 혼주</p>
                            <div className="contact-to-hosts__content">
                                <HostContact
                                    side="신랑"
                                    role="아버지"
                                    name="김재홍"
                                    color="blue"
                                    phoneNumber="010-3772-7256"
                                />
                            </div>
                        </div>
                        <div className="contact-to-hosts contact-to-hosts--side-bride contact__to-hosts">
                            <p className="contact-to-hosts__title">신부측 혼주</p>
                            <div className="contact-to-hosts__content">
                                <HostContact
                                    role="아버지"
                                    name="오연식"
                                    color="red"
                                    phoneNumber="010-4559-0691"
                                />
                                <HostContact
                                    role="어머니"
                                    name="전애희"
                                    color="red"
                                    phoneNumber="010-3548-5885"
                                />
                            </div>
                        </div>
                    </main>
                </section>
                <a className="popup__close-button" href="#" onClick={onClose}></a>
            </div>
        </div>
    )
}