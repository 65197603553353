import { useState } from "react";
import clsx from "clsx";
import calendarNumber from "img/number_calendar.png";

export default function FrontCover(props) {
    const { coverRemoved, onRemoveCover } = props;

    return (
        <div className={clsx("front-cover", coverRemoved && "front-cover--removed")} onTouchStart={onRemoveCover} onMouseDown={onRemoveCover}>
            <div className="front-cover__container">
                <p className="front-cover__title">
                    초대합니다
                </p>
                <p className="front-cover__date">
                    <img src={calendarNumber}/>
                </p>
            </div>
        </div>
    );
}