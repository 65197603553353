import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import 'swiper/css';
import {useScroll} from "../helpers/Hooks";
import {useRef} from "react";

export default function StarPage(props) {
    const {
        className,
        position,
        means,
        pictures,
        name,
        birthday,
        job,
        ability,
        animated
    } = props;
    const positionRef = useScroll();
    const sliderRef = useScroll();
    const meansRef = useScroll();
    const contentRef = useScroll();

    const pictureElements = pictures.map((picture, index) =>
        <SwiperSlide key={index}>
            <img src={picture}/>
        </SwiperSlide>
    );

    return (
        <div className={clsx("page", "star-page", className)}>
            <h1 className="star-position fadeInUp" { ...positionRef }>{`${position === "신랑" ? "신랑을" : "신부를"} 소개합니다`}</h1>
            <div className="star-means fadeInUp" { ...meansRef }>
                <p className="star-means__name">
                    {`${position === "신랑" ? "신부가" : "신랑이" } 말하는 ${position === "신랑" ? position : "신부"}`}
                </p>
                <p className="star-means__value">
                    "{means}"
                </p>
            </div>
            <div className="star-slider fadeInUp" { ...sliderRef }>
                <Swiper
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                    }}
                    allowTouchMove={false}
                    spaceBetween={10}
                    navigation={true}
                    modules={[FreeMode, Navigation]}
                >
                    {pictureElements}
                </Swiper>
            </div>
            <div className="star-content fadeInUp" { ...contentRef }>
                <p className="star-content__name">{name}</p>
                <div className="star-content__value">
                    <p className="star-content__birthday">{birthday}</p>
                    <p className="star-content__job">{job}</p>
                </div>
            </div>
        </div>
    )
}